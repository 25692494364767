import React from "react";
import { Routes, Route } from "react-router";
import { WrongPath } from "./WrongPath";
import { LoginPage } from "./Components/Login/LoginPage";
import { CheckAuth } from "./auth/CheckAuth";
import { LoginKeeper } from "./auth/LoginKeeper";
import UsersListing from "./Components/Users/UsersListing";
import { Backdrop } from "@mui/material";
import { Triangle } from "react-loader-spinner";
import { useSelector } from "react-redux";
import AddTheme from "./Components/AddTheme/AddTheme";
import Eventmanagement from "./Components/EventManagement/Eventmanagement";
import DropdownWithEditDelete from "./Components/testing"

export const Router = () => {
  let loader=useSelector(e=>e.loader)
  return (
    <>
     <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
            <Triangle
              height="80"
              width="80"
              color="black"
              ariaLabel="triangle-loading"
              visible={loader}
            />
        </Backdrop>
      <Routes>
        <Route element={<LoginKeeper />}>
          <Route path="/login" element={<LoginPage />} />
        </Route>

        <Route element={<CheckAuth />}>
          {/* <Route exact path="/qrcode" element={<Qrcode />} /> */}
          <Route exact path="/" element={<UsersListing />} />
          <Route exact path="/addTheme" element={<AddTheme />} />
          <Route exact path="/eventmanagement" element={<Eventmanagement />} />
          <Route exact path="/testing" element={<DropdownWithEditDelete />} />
          {/* <Route exact path="/testing" element={<div>Test Route</div>} /> */}

        </Route>
        <Route path="*" element={<WrongPath />} />
      </Routes>
    </>
  );
}